import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { useLocation } from "react-router-dom";

function Officers() {
  const location = useLocation();

  const pageTitle = "Our Officers | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County officers provide leadership and direction for the county party. Learn more about DPCC officers."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Our Officers" }} />

      <section>
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col-lg-12 pt-3">
              <div className="card">
                <div className="card-header">
                  <h4>Officers for the Democratic Party of Clackamas County</h4>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      <strong>Chair:</strong> Cris Waller,{" "}
                      <a href="mailto:char@clackamasdems.org">chair@clackamasdems.org</a>
                    </li>
                    <li>
                      <strong>First Vice-Chair:</strong> James Farley,{" "}
                      <a href="mailto:first-vice-chair@clackamasdems.org">
                        first-vice-chair@clackamasdems.org
                      </a>
                    </li>
                    <li>
                      <strong>Second Vice-Chair:</strong> Marchelle Paholsky,{" "}
                      <a href="mailto:second-vice-chair@clackamasdems.org">
                        second-vice-chair@clackamasdems.org
                      </a>
                    </li>
                    <li>
                      <strong>Recording Secretary:</strong> Jaime Sanders,{" "}
                      <a href="mailto:secretary@clackamasdems.org">secretary@clackamasdems.org</a>
                    </li>
                    <li>
                      <strong>Digital Secretary:</strong> Jason Rambo,{" "}
                      <a href="mailto:digital-secretary@clackamasdems.org">
                        digital-secretary@clackamasdems.org
                      </a>
                    </li>
                    <li>
                      <strong>Treasurer:</strong> Jan Lee Weinberg,{" "}
                      <a href="mailto:treasurer@clackamasdems.org">treasurer@clackamasdems.org</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Officers;
