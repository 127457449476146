import React, { Fragment } from "react";
import Banner from "./comps/Banner";
import Breadcrumbs from "../../layouts/Breadcrumbs";

function Content() {
  const userBrowser = navigator.userAgent;

  const hideInBrowser = () => {
    if (userBrowser.includes("Safari")) {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <Breadcrumbs breadcrumb={{ pagename: "Event Calendar" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBlockStart: "36px",
          paddingBlockEnd: "36px",
          margin: "0 auto",
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <p>
          See{" "}
          <a href="https://calendar.google.com/calendar/u/0/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America/Los_Angeles&src=Zm1kY2wyMWs0OWJjMTBxYTdnbG5qdTk0amNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23743500&showTabs=1&showCalendars=0&mode=AGENDA&showDate=0">
            all upcoming Democratic events
          </a>{" "}
          in Clackamas County.
        </p>
      </div>
    </Fragment>
  );
}

export default Content;
