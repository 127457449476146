import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import profile from "../../assets/img/banner/pride-profile-logo.png";
import { useLocation } from "react-router-dom";

function OurMission() {
  const location = useLocation();

  const pageTitle = "How to Influence your Legislators | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="The Democratic Party of Clackamas County works to elect progressive leaders at the local, state and national levels. Learn more about our mission and join us."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "How to Influence your Legislators" }} />
      <section className="client-section">
        <div className="container">
          <div className="caucuses p-3 px-md-5" name="caucuses">
            <br />
            <h2><a href="/olis-training">OLIS Training</a></h2>
            <hr />
            <h2>Links from the training:</h2>
            <ul>
              <li><a target="_blank" href="https://olis.oregonlegislature.gov/">OLIS</a></li>
              <li><a href="https://geo.maps.arcgis.com/apps/instant/lookup/index.html?appid=fd070b56c975456ea2a25f7e3f4289d1">Find your Legislator</a></li>
              <li><a href="https://public.govdelivery.com/accounts/ORLEG/subscriber/new">Subscribe to legislator emails</a></li>
            </ul>
            <hr />
            <h2>Other important links:</h2>
            <ul>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/OLIS_help/Pages/SessionDay.aspx#Index">OLIS Help</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/citizen_engagement/Pages/Find-a-Bill.aspx">How to Find a Bill or Vote Information</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/citizen_engagement/Pages/How-to-Testify.aspx">How to Testify in Committee Hearings</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/citizen_engagement/Pages/Submit-Exhibits.aspx">How to Register to Testify and Submit Written Testimony</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/senate/Pages/SenatorsDistrict.aspx">Oregon State Senators</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/senate">Senate Leadership</a> (check under "Senate Leadership" on left-hand side of page)</li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/house/Pages/RepresentativesDistrict.aspx">Oregon State Representatives</a></li>
              <li><a target="_blank" href="https://www.oregonlegislature.gov/house">House Leadership</a> (check under "House Leadership" on left-hand side of page)</li>
              <li><a target="_blank" href="https://www.acludc.org/en/tips-and-template-written-and-oralrecorded-testimony">Tips and Template for Writing Testimony</a></li>
              <li><a target="_blank" href="https://www.aclu.org/writing-your-elected-representatives">Writing your Elected Representatives</a></li>
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default OurMission;
