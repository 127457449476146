import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import { useLocation } from "react-router-dom";
function HouseDistrictLeaders() {
  const location = useLocation();

  const pageTitle = "House District Leaders | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  const districtLeaders = [
    {
      district: "18",
      leaders: [
        {
          name: "Jennifer Rueda",
          email: "pebblesmom1@hotmail.com",
        },
      ],
    },
    {
      district: "26",
      leaders: [
        {
          name: "Bill Bagnall",
          email: "billybagnall@icloud.com",
        },
        {
          name: "Mary Rooney",
          email: "maryparker20@gmail.com",
        },
      ],
    },
    {
      district: "37",
      leaders: [
        {
          name: "Cheryl Wyborny",
          email: "wybornycheryl@outlook.com",
        },
        {
          name: "Andrea Philips",
          email: "andrea@andreajphillips.com",
        },
      ],
    },
    {
      district: "38",
      leaders: [
        {
          name: "Don Dutton",
          email: "dnlddttn@comcast.net",
        },
        {
          name: "Gary Thompson",
          email: "garyallenthompson@gmail.com",
        },
      ],
    },
    {
      district: "39",
      leaders: [
        {
          name: "Julie Lund",
          email: "julieserralund@gmail.com",
        },
        {
          name: "Susan Plack",
          email: "plaxxxx@gmail.com",
        },
      ],
    },
    {
      district: "40",
      leaders: [
        {
          name: "Mike Weber",
          email: "skf_mikeweber@hotmail.com",
        },
      ],
    },
    {
      district: "41",
      leaders: [
        {
          name: "Will Anderson",
          email: "williamvanderson3@gmail.com",
        },
        {
          name: "Janis Betten",
          email: "lady.1r1s.1945@gmail.com",
        },
      ],
    },
    {
      district: "48",
      leaders: [
        {
          name: "Walter Hopgood",
          email: "walterhpdx@me.com",
        },
      ],
    },
    {
      district: "51",
      leaders: [],
    },
    {
      district: "52",
      leaders: [
        {
          name: "Lance Erickson",
          email: "Lance.E.Erickson@gmail.com",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get to know DPCC House District Leaders. Our House District Leaders mentor PCPs and Neighborhood Leaders and are resources for registered Democrats."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "House District Leaders" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p style={{ marginBottom: "14px" }}>
                    House District Leaders act as resources for registered
                    Democrats in their districts. House District Leaders help
                    mentor Democrats who want to{" "}
                    <a
                      href="https://www.clackamasdems.org/#/pcp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      become PCPs
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.clackamasdems.org/#/neighborhood-leaders-program"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Neighborhood Leaders
                    </a>
                    . If you’d like to get involved, please get in touch!
                  </p>
                  <a
                    href="https://www.clackamasdems.org/#/contacts-and-organization"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.clackamasdems.org/#/contacts-and-organization
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col">
              <p>
                Please Note: If you’re not sure of your Oregon State House
                District (HD),{" "}
                <a
                  href="https://www.oregonlegislature.gov/FindYourLegislator/leg-districts-mobile_new.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  you can find it by clicking this link
                </a>
                .
              </p>
              {districtLeaders.map((district) => {
                return (
                  <div className="card mb-5" key={district.district}>
                    <div className="card-header">
                      <h3>HD - {district.district}</h3>
                    </div>
                    <div className="card-body">
                      <ul>
                        {!district.leaders.length && <li> Vacant</li>}
                        {district.leaders.map((leader) => {
                          return (
                            <li key={leader.email}>
                              <p>
                                {leader.name} -{" "}
                                <a href={`mailto:${leader.email}`}>
                                  {leader.email}
                                </a>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default HouseDistrictLeaders;
