import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import Preloader from "./components/layouts/Preloader";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Blogstandard from "./components/pages/Blogstandard";
import Blogdetail from "./components/pages/Blogdetail";
import ContactsAndOrganization from "./components/pages/ContactsAndOrganization";
import EventsCalendar from "./components/pages/EventsCalendar";
import PCP from "./components/pages/PCP";
import OurMission from "./components/pages/OurMission";
import Officers from "./components/pages/Officers";
import HouseDistrictLeaders from "./components/pages/HouseDistrictLeaders";
import StandingCommittee from "./components/pages/StandingCommittee";
import Delegates from "./components/pages/Delegates";
import DpccByLaw from "./components/pages/DpccByLaw";
import Documentation from "./components/pages/Documentation";
import CommitteeMeetings from "./components/pages/CommitteeMeetings";
import StandingRules from "./components/pages/StandingRules";
import ParliamentaryProcedure from "./components/pages/ParliamentaryProcedure";
import OtherWaysToDonate from "./components/pages/OtherWaysToDonate";
import Platform from "./components/pages/Platform";
import GettingReadyToRun from "./components/pages/CandidatePortal/GettingReadyToRun";
import ShouldYouRun from "./components/pages/CandidatePortal/ShouldYouRun";
import CandidateCommunicationsStrategy from "./components/pages/CandidatePortal/CandidateCommunicationsStrategy";
import CandidateTraining from "./components/pages/CandidatePortal/CandidateTraining";
import CandidateResources from "./components/pages/CandidatePortal/CandidateResources";
import Donate from "./components/pages/Donate";
import OregonTrailDemocrats from "./components/pages/OregonTrailDemocrats";
import Volunteer from "./components/pages/Volunteer";
import NeighborhoodLeaderProgram from "./components/pages/NeighborhoodLeader/Program";
import NeighborhoodLeaderTurfCutters from "./components/pages/NeighborhoodLeader/TurfCutters";
import NeighborhoodLeaderTrainingAndResources from "./components/pages/NeighborhoodLeader/TrainingAndResources";
import NeighborhoodLeaderGetHelp from "./components/pages/NeighborhoodLeader/GetHelp";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import HowToInfluenceYourLegislators from "./components/pages/HowToInfluenceYourLegislators";

export default () => {
  return (
    <Router>
      <Preloader />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="/contacts-and-organization"
          component={ContactsAndOrganization}
        />
        <Route path="/about-us" component={About} />
        <Route path="/blog-posts" exact component={Blogstandard} />
        <Route path="/news" exact component={Blogstandard} />
        <Route path="/blog-posts/:title" component={Blogdetail} />
        <Route path="/standing-rules" component={StandingRules} />
        <Route
          path="/parliamentary-procedure"
          component={ParliamentaryProcedure}
        />
        <Route path="/other-ways-to-donate" component={OtherWaysToDonate} />
        <Route
          path="/neighborhood-leader/program"
          component={NeighborhoodLeaderProgram}
        />
        <Route
          path="/neighborhood-leader/training-and-resources"
          component={NeighborhoodLeaderTrainingAndResources}
        />
        <Route
          path="/neighborhood-leader/resources-for-turf-cutters"
          component={NeighborhoodLeaderTurfCutters}
        />
        <Route
          path="/neighborhood-leader/get-help"
          component={NeighborhoodLeaderGetHelp}
        />
        <Route
          path="/oregon-trail-democrats"
          component={OregonTrailDemocrats}
        />
        <Route path="/events-calendar" component={EventsCalendar} />
        <Route path="/pcp" component={PCP} />
        <Route path="/our-mission" component={OurMission} />
        <Route path="/officers" component={Officers} />
        <Route
          path="/house-district-leaders"
          component={HouseDistrictLeaders}
        />
        <Route
          path="/standing-committee-and-caucuses"
          component={StandingCommittee}
        />
        <Route path="/delegates" component={Delegates} />
        <Route path="/dpcc-by-laws" component={DpccByLaw} />
        <Route path="/documentation" component={Documentation} />
        <Route path="/platform" component={Platform} />
        <Route path="/donate" component={Donate} />
        <Route
          path="/join-our-central-committee-meetings"
          component={CommitteeMeetings}
        />
        <Route path="/volunteer-your-time" component={Volunteer} />
        <Route
          path="/candidate-portal/getting-ready-to-run"
          component={GettingReadyToRun}
        />
        <Route
          path="/candidate-portal/should-you-run"
          component={ShouldYouRun}
        />
        <Route
          path="/candidate-portal/candidate-communications-strategy"
          component={CandidateCommunicationsStrategy}
        />
        <Route
          path="/candidate-portal/candidate-training"
          component={CandidateTraining}
        />
        <Route
          path="/candidate-portal/candidate-resources"
          component={CandidateResources}
        />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/how-to-influence-your-legislators" component={HowToInfluenceYourLegislators} />
      </Switch>
    </Router>
  );
};
