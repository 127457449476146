import React from "react";

function Content() {
  return (
    <section className="client-section">
      <div className="container">
        <div className="client-slider section-gap line-top">
          <DelegatesStateCentralCommittee />
          <br />
          <DelegatesCongressionalDistrictThree />
          <br />
          <DelegatesCongressionalDistrictFive />
          <br />
          <DelegatesCongressionalDistrictSix />
        </div>
      </div>
    </section>
  );
}

function DelegatesCongressionalDistrictSix() {
  const delegates = ["Roger Fontes- Chair", "Cyrus Khemalaap"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 6
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesCongressionalDistrictFive() {
  const delegates = [
    "Deb Barnes- Chair",
    "Daniel Dyre-Greensite",
    "Susan Rodway",
    "Ed Cator",
    "Eleanor Wynn",
    "Adam Kane ",
    "Charles Gallia",
  ];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 5
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesCongressionalDistrictThree() {
  const delegates = ["Jan Lee- Chair", "Nick Walden Poublon"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 3
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesStateCentralCommittee() {
  const delegates = [
    "Nick Walden Poublon- Chair",
    "Marie Karns",
    "Leonard Dunn",
    "Marchelle Paholsky",
    "Charles  Gallia",
    "Deborah Barnes",
    "Lance Erickson",
  ];
  const alternates = [
    "Jan Lee",
    "Mike Gardner",
    "Laurie Kilbourn",
    "Ed Cator",
    "Eleanor Wynn",
    "Adam Kane",
    "Laurie Cremona-Wagner",
    "Rob Wagner",
  ];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates State Central Committee
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
        <h5 className="card-title">
          <i className="fa fa-users" /> Alternates
        </h5>
        <p className="card-text p-5">
          <ul>
            {alternates.map((alternate) => (
              <li key={alternate}>{alternate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

export default Content;
